import Image from 'next/image';

import {
  Section,
  Title,
  Partners,
  ContainerTextMobile,
  H1TextMobile,
  H4TextMobile,
} from './OurPartners.styles';

import ambev from '@/public/images/clientes/ambev.png';
import globo from '@/public/images/clientes/globo.png';
import bradesco from '@/public/images/clientes/bradesco.png';
import gm from '@/public/images/clientes/gm.png';
import google from '@/public/images/clientes/google.png';
import mercado_livre from '@/public/images/clientes/mercado_livre.png';
import governo_federal from '@/public/images/clientes/governo_federal.png';
import petrobras from '@/public/images/clientes/petrobras.png';

export const OurPartners = () => {
  return (
    <Section>
      {/* Title */}
      <Title>Faça como os maiores anunciantes do país</Title>

      {/* Partners */}
      <Partners>
        {partners.map((partner, idx) => (
          <Image
            src={partner}
            width={75}
            height={75}
            key={idx}
            alt="Logo do Anunciante"
            style={{
              filter: 'brightness(0.5)',
              objectFit: 'contain',
            }}
          ></Image>
        ))}
      </Partners>

      <ContainerTextMobile>
        <H1TextMobile>Mais criatividade com playlists</H1TextMobile>
        <H4TextMobile>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt uta. dolore magna aliqua. Quis ipsum
          suspendisse ultrices grave acilisis coletanbus.{' '}
        </H4TextMobile>
      </ContainerTextMobile>
    </Section>
  );
};

const partners = [
  ambev,
  globo,
  bradesco,
  gm,
  google,
  mercado_livre,
  governo_federal,
  petrobras,
];
