import Image from 'next/image';

import {
  SectionContainer,
  LeftAside,
  RightAside,
  Subtitle,
  Title,
  Description,
  SearchContainer,
  Tags,
  StyledInput,
  InputContainer,
  StyledButton,
  StyledLink,
  StyledVideo,
} from './NewWayToSearch.styles';
import { Search } from '@mui/icons-material';
import { useRouter } from 'next/navigation';
import { useState } from 'react';

export const NewWayToSearch = () => {
  const [search, setSearch] = useState('');
  const router = useRouter();

  const handleSearch = () => {
    if (!search) return;

    router.push(`/search?search_text=${search}`);
  };

  return (
    <SectionContainer id="new-way-to-search">
      {/* Left Side */}
      <LeftAside>
        {/* Title */}
        {/* Subtitle */}
        <Subtitle>O Brasil que você procurava está aqui</Subtitle>
        <Title>
          Um banco <br /> de vídeos cheio de brasilidade
        </Title>
        {/* Description */}
        <Description>
          Chega de passar sufoco com seu banco de imagens. A Bamboo reúne os
          melhores vídeos nacionais cheios de brasilidade para você usar em suas
          criações.
        </Description>
        {/* Search Component */}
        <SearchContainer>
          <InputContainer>
            <Search style={{ color: '#969696', fontSize: '22px' }} />
            <StyledInput
              type="text"
              placeholder="Pesquise por Bahia, Agro..."
              onChange={(e) => setSearch(e.target.value)}
            />

            <StyledButton variant="contained" onClick={handleSearch}>
              Buscar
            </StyledButton>
          </InputContainer>
          <Tags>
            Principais tags:{' '}
            {tags.map((tag, index) => (
              <StyledLink href={`/search?search_text=${tag}`} key={tag + index}>
                {tag}
                {index < tags.length - 1 && ', '}
              </StyledLink>
            ))}
          </Tags>
        </SearchContainer>
      </LeftAside>

      {/* Right Side */}
      <RightAside>
        {/* Image */}

        <StyledVideo
          autoPlay
          loop
          muted
          controls={false}
          playsInline
          style={{ pointerEvents: 'none' }}
        >
          <source
            src="https://bamboo-ecommerce-public.s3.sa-east-1.amazonaws.com/static/bmb_home_v10.mp4"
            type="video/mp4"
          />
        </StyledVideo>
      </RightAside>
    </SectionContainer>
  );
};

const tags = ['família', 'pessoas', 'lugares', 'cidades', 'felicidade'];
