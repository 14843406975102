import { Button, useMediaQuery } from '@mui/material';
import { useRouter } from 'next/navigation';

import {
  Section,
  Content,
  Title,
  Description,
  Plans,
  Plan,
  RightSide,
  LeftSide,
  Person1,
  Person4,
  Person2,
  Person3,
  Person5,
  PersonContainer,
  RightSideBackground,
} from './RightPlan.styles';
import { useTheme } from '@mui/material/styles';

import HiddenComponent from '@bamboo/core-lib/components/HiddenComponent';

export const RightPlan = () => {
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down('md'), {
    noSsr: true,
  });
  const router = useRouter();

  function handlePlansPage() {
    router.push('/plans');
  }

  return (
    <Section>
      {/* Content */}
      <div
        style={{
          borderRadius: '50px',
          color: '#fff',
          backgroundColor: '#051315',
          backgroundImage: 'linear-gradient(135deg, #1c2b3a, #000000)',
        }}
      >
        <Content>
          <LeftSide>
            {/* Plans */}
            <HiddenComponent hidden={!isSm}>
              <Plans>
                {plans.map((plan, i) => (
                  <Plan key={plan + i}>{plan}</Plan>
                ))}
              </Plans>
            </HiddenComponent>

            {/* Title */}
            <HiddenComponent hidden={isSm}>
              <Title>
                Diversos planos para
                <br />
                complementar sua produção
              </Title>
            </HiddenComponent>
            <HiddenComponent hidden={!isSm}>
              <Title>
                Diversos
                <br />
                planos para complementar <br />
                sua produção
              </Title>
            </HiddenComponent>

            {/* Description */}
            <Description>
              Tenha acesso a vídeos cinematográficos cheios de brasilidade e
              diversidade para utilizar em todas as mídias, inclusive na TV, e
              economize nas produções de seus comerciais.
            </Description>

            {/* Plans */}
            <HiddenComponent hidden={isSm}>
              <Plans>
                {plans.map((plan, i) => (
                  <Plan key={plan + i}>{plan}</Plan>
                ))}
              </Plans>
            </HiddenComponent>

            <Button
              variant="contained"
              onClick={handlePlansPage}
              style={{
                backgroundColor: '#FFF',
                borderRadius: '42px',
                height: '64px',
                width: '100%',
                maxWidth: '300px',
                color: '#000',
                fontSize: '18px',
                fontWeight: 500,

                ...(isSm && {
                  color: '#222',
                  maxWidth: '100%',
                  fontWeight: 600,
                  marginTop: '30px',
                }),
              }}
            >
              Conheça os planos
            </Button>
          </LeftSide>

          {/* <RightSideBackground></RightSideBackground> */}
          <RightSide>
            <Person1 src="/images/person-1.png" alt="" />
            <Person2 src="/images/person-2.png" alt="" />
            <PersonContainer>
              <Person3 src="/images/person-3.png" alt="" />
              <Person4 src="/images/person-4.png" alt="" />
            </PersonContainer>
            <Person5
              src="/images/person-5.png"
              alt=""
              width={281}
              height={367}
            />
          </RightSide>
        </Content>
      </div>
    </Section>
  );
};

const plans = ['Start •', 'Plus •', 'Pro •', 'Enterprise'];
