import { styled } from '@mui/material/styles';

const min1441 = '@media (min-width:1441px)';
const max1024 = '@media (max-width:1024px)';
const max930 = '@media (max-width:930px)';

export const Section = styled('section')(({ theme }) => ({
  padding: '0 30px 0 40px',

  p: {
    margin: 0,
  },

  [theme.breakpoints.down('md')]: {
    paddingInline: '20px',
  },
}));

export const Content = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  justifyContent: 'space-between',

  // backgroundImage: "url('/images/right-plan-background.jpg')",
  backgroundImage: "url('/images/graphic-plan-background.png')",
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'bottom right',
  backgroundSize: '60%',

  overflow: 'hidden',

  [min1441]: {
    backgroundSize: '75%',
  },

  [theme.breakpoints.down('md')]: {
    backgroundSize: '200%',
    backgroundPosition: 'center 380px',
    borderRadius: '64px',
    flexDirection: 'column',
  },

  [max930]: {
    backgroundImage: 'none',
  },
}));

export const LeftSide = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'center',
  textAlign: 'start',
  gap: '30px',

  color: '#fff',
  borderRadius: '50px',
  paddingBlock: '60px',
  paddingLeft: '70px',

  [min1441]: {
    paddingBlock: '80px',
    paddingLeft: '90px',
  },

  [theme.breakpoints.down('md')]: {
    order: '2',
    borderRadius: '64px',
    paddingInline: '40px',
    paddingBlock: '50px 30px',
    gap: '16px',
  },
}));

export const Title = styled('p')(({ theme }) => ({
  fontSize: '40px',
  lineHeight: '32px',
  fontWeight: 500,
  color: '#FFF',
  width: '100%',
  maxWidth: '675px',

  [min1441]: {
    fontSize: '36px',
    lineHeight: '38px',
  },

  [theme.breakpoints.down('md')]: {
    fontSize: '30px',
    lineHeight: '30px',
  },
}));

export const Description = styled('p')(() => ({
  fontSize: '12px',
  lineHeight: '20px',
  fontWeight: 300,
  color: '#fff',
  width: '100%',
  maxWidth: '534px',

  [max1024]: {
    fontSize: '16px',
    lineHeight: '24px',
  },

  [max930]: {
    fontSize: '12px',
    lineHeight: '17px',
    marginTop: '10px !important',
  },
}));

export const Plans = styled('div')(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '4px',

  background:
    'linear-gradient(90deg, rgb(243, 31, 154) 0%, rgb(59, 124, 251) 100%)',
  WebkitBackgroundClip: 'text',
  color: 'transparent',
}));

export const Plan = styled('div')(({ theme }) => ({
  display: 'flex',
  fontSize: '30px',
  lineHeight: '30px',
  fontWeight: 600,

  [theme.breakpoints.down('md')]: {
    fontSize: '24px',
    lineHeight: '24px',
    fontWeight: 500,
  },

  [max930]: {
    fontSize: '19px',
    lineHeight: '19px',
  },
}));

export const RightSideBackground = styled('div')(({ theme }) => ({}));

export const RightSide = styled('div')(({ theme }) => ({
  paddingLeft: '55px',
  paddingRight: '10px',

  width: '1000px',
  display: 'flex',
  flexDirection: 'row',
  gap: '12px',

  img: {
    borderRadius: '24px',
    objectFit: 'cover',

    [max930]: {
      borderRadius: '10px',
    },
  },

  [max930]: {
    gap: '4px',
    // display: 'none',
    order: '1',
    position: 'relative',
    left: '-45px',
  },
}));

export const Person1 = styled('img')(({ theme }) => ({
  // width: '246px',
  width: '15.81vw',
  height: '401px',
  marginTop: '110px',
  marginBottom: '-20px',

  [max1024]: {
    marginTop: '180px',
    width: '25.81vw',
    height: '300px',
  },

  [max930]: {
    marginTop: '95px',
    width: '25vw',
    height: '150px',
  },
}));

export const Person2 = styled('img')(({ theme }) => ({
  width: '13.71vw',
  height: '296px',
  marginTop: '15px',

  [max1024]: {
    marginTop: '90px',
    width: '23.5vw',
    height: '200px',
  },

  [max930]: {
    marginTop: '50px',
    width: '23.5vw',
    height: '125px',
  },
}));

export const PersonContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  marginTop: '-38px',

  [max1024]: {
    marginTop: '40px',
  },
  [max930]: {
    gap: '4px',
    marginTop: '20px',
  },
}));

export const Person3 = styled('img')(({ theme }) => ({
  // width: '175px',
  width: '11.11vw',
  height: '190px',

  [max1024]: {
    width: '18.81vw',
    height: '130px',
  },

  [max930]: {
    width: '17vw',
    height: '86px',
  },
}));

export const Person4 = styled('img')(({ theme }) => ({
  // width: '175px',
  width: '11.11vw',
  height: '190px',

  [max1024]: {
    width: '18.81vw',
    height: '120px',
  },

  [max930]: {
    width: '17vw',
    height: '78px',
  },
}));

export const Person5 = styled('img')(({ theme }) => ({
  // width: '281px',
  width: '14.63vw',
  height: '367px',
  marginTop: '53px',

  [max1024]: {
    marginTop: '160px',
    width: '24.81vw',
    height: '200px',
  },

  [max930]: {
    marginTop: '75px',
    width: '26vw',
    height: '140px',
  },
}));
