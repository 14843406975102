import { homeConfig } from '../home.config';
import { useTranslation } from 'next-i18next';
import dynamic from 'next/dynamic';
import { NewWayToSearch } from '../components/sections/new-way-to-search/NewWayToSearch';
import { RightPlan } from '../components/sections/right-plan/RightPlan';
import { OurPartners } from '../components/sections/our-partners/OurPartners';
import { NoLimits } from '../components/sections/no-limits/NoLimits';
import { Categories } from '../components/sections/categories/Categories';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import HiddenComponent from '@bamboo/core-lib/components/HiddenComponent';
import { Filmmakers } from '../components/sections/filmmakers/Filmmakers';

const ImageObjectJsonLd = dynamic(() => import('@/lib/seo/ImageObjectJsonLd'));
const WebPageJsonLd = dynamic(() => import('@/lib/seo/WebPageJsonLd'));
const BreadCrumbListJsonLd = dynamic(
  () => import('@/lib/seo/BreadCrumbListJsonLd')
);
const Paperbase = dynamic(() => import('@/features/home/components/Paperbase'));

const HomePage = () => {
  const theme = useTheme();
  const { t } = useTranslation(homeConfig.i18nNamespaces);

  const isSm = useMediaQuery(theme.breakpoints.down('md'), {
    noSsr: true,
  });

  return (
    <Paperbase
      seoProps={{
        title: t('home:page.title'),
        canonical: process.env.ECOMMERCE_URL,
        additionalMetaTags: [
          {
            name: 'description',
            content: t('common:seo.description'),
          },
          {
            property: 'og:description',
            content: t('common:seo.description'),
          },
          {
            property: 'og:url',
            content: process.env.ECOMMERCE_URL ?? '',
          },
          {
            property: 'og:image',
            content: `${process.env.ECOMMERCE_URL}/images/seoImage.jpg`,
          },
          {
            property: 'og:image:width',
            content: '1000',
          },
          {
            property: 'og:image:height',
            content: '1000',
          },
          {
            name: 'twitter:image',
            content: `${process.env.ECOMMERCE_URL}/images/seoImage.jpg`,
          },
          {
            name: 'twitter:label1',
            content: t('home:page.seo.estimatedTime'),
          },
          {
            name: 'twitter:data1',
            content: t('home:page.seo.16Mins'),
          },
          {
            name: 'google-site-verification',
            content: process.env.GOOGLE_SITE_VERIFICATION ?? '',
          },
        ],
      }}
      foolerLabels={{
        helpTitle: t('common:footer.helpTitle'),
        helpDescription: t('common:footer.helpDescription'),
        helpButtonLabel: t('common:footer.helpButtonLabel'),
        infoTitle: t('common:footer.infoTitle'),
        infoProjects: t('common:footer.infoProjects'),
        infoAccount: t('common:footer.infoAccount'),
        infoFAQ: t('common:footer.infoFAQ'),
        infoHelp: t('common:footer.infoHelp'),
        aboutTitle: t('common:footer.aboutTitle'),
        aboutWhoWeAre: t('common:footer.aboutWhoWeAre'),
        aboutJobs: t('common:footer.aboutJobs'),
        licensingTitle: t('common:footer.licensingTitle'),
        licensingPlaylist: t('common:footer.licensingPlaylist'),
        licensingSearch: t('common:footer.licensingSearch'),
      }}
    >
      <ImageObjectJsonLd
        url={`${process.env.ECOMMERCE_URL}/images/seoImage.jpg`}
        contentUrl={`${process.env.ECOMMERCE_URL}/images/seoImage.jpg`}
        id={`${process.env.ECOMMERCE_URL}/#primaryimage`}
        width={1000}
        height={1000}
      />
      <WebPageJsonLd
        url={process.env.ECOMMERCE_URL ?? ''}
        id={`${process.env.ECOMMERCE_URL}/#webpage`}
        name={t('home:page.title')}
        about={{ '@id': `${process.env.ECOMMERCE_URL}/#organization` }}
        primaryImageOfPage={{
          '@id': `${process.env.ECOMMERCE_URL}/#primaryimage`,
        }}
        breadcrumb={{ '@id': `${process.env.ECOMMERCE_URL}/#breadcrumb` }}
        datePublished=""
        dateModified=""
        potentialAction={[
          {
            '@type': 'ReadAction',
            target: [process.env.ECOMMERCE_URL ?? ''],
          },
        ]}
      />
      <BreadCrumbListJsonLd
        id={`${process.env.ECOMMERCE_URL}/#breadcrumb`}
        itemListElement={[
          {
            '@type': 'ListItem',
            position: 1,
            name: t('home:page.bread_crumbs.home'),
          },
        ]}
      />
      <NewWayToSearch />
      <OurPartners />
      <Categories />

      <HiddenComponent hidden={!isSm}>
        <NoLimits />
      </HiddenComponent>

      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          backgroundImage: "url('/images/bamboo-icon.svg')",
          backgroundRepeat: 'no-repeat',
          backgroundPosition: '-1000px -499px',
          backgroundSize: '3000px',
        }}
      >
        <HiddenComponent hidden={!isSm}>
          <RightPlan />
        </HiddenComponent>

        <Filmmakers />

        <HiddenComponent hidden={isSm}>
          <RightPlan />
        </HiddenComponent>
      </div>
      <HiddenComponent hidden={isSm}>
        <NoLimits />
      </HiddenComponent>
    </Paperbase>
  );
};

export default HomePage;
