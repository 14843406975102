import { Button, SvgIcon } from '@mui/material';
import { styled } from '@mui/material/styles';
import Link from 'next/link';

const min1536 = '@media (min-width:1536px)';
const max1600 = '@media (max-width:1600px)';
const max1100 = '@media (max-width:1100px)';
const max1280 = '@media (max-width:1280px)';
const max930 = '@media (max-width:930px)';

export const SectionContainer = styled('section')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '105px',
  justifyContent: 'space-between',
  padding: '0 30px 0 111px',
  marginTop: '90px',

  'h1, h2, p': {
    margin: 0,
    padding: 0,
  },

  [max1100]: {
    flexDirection: 'column',
    gap: '24px',
    paddingInline: '40px',
  },

  [max930]: {
    padding: '0 20px',
    paddingInline: '20px',
    marginTop: '120px',
  },
}));

export const LeftAside = styled('aside')(({ theme }) => ({
  width: '100%',
  maxWidth: '403px',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: 'auto 0',

  [max1100]: {
    maxWidth: '100%',
    marginTop: '0px',
  },
}));

export const Subtitle = styled('h2')(({ theme }) => ({
  fontSize: '15px',
  lineHeight: '25px',
  fontWeight: 400,
  color: '#3a3a3a',
  maxWidth: '385px',

  [max1100]: {
    textAlign: 'center',
    maxWidth: '100%',
  },
  [max930]: {
    fontSize: '12px',
    marginBottom: '15px !important',
  },
}));

export const Title = styled('div')(({ theme }) => ({
  fontSize: '45px',
  lineHeight: '45px',
  fontWeight: 500,
  color: '#000000',

  [max930]: {
    fontSize: '36px',
    padding: '0 20px',
  },

  [max1100]: {
    textAlign: 'center',
  },
}));

export const Description = styled('p')(({ theme }) => ({
  fontSize: '15px',
  lineHeight: '22px',
  fontWeight: 400,
  color: '#3A3A3A',
  marginBlock: '26px 26px !important',

  [max1100]: {
    textAlign: 'center',
    fontSize: '12px',
    padding: '0 120px !important',
  },

  [theme.breakpoints.down('md')]: {
    padding: '0 20px !important',
  },
}));

export const SearchContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',

  [max1100]: {
    marginBottom: '20px',
    alignItems: 'center',
    gap: '16px',
  },
}));

export const Search = styled(SvgIcon)(({ theme }) => ({
  color: '#969696',
  fontSize: '28px',

  [max930]: {
    fontSize: '18px',
  },
}));
export const InputContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: '25px',
  alignItems: 'center',
  width: '530px',
  background: '#fff',
  borderRadius: '50px',
  border: 'none',
  // boxShadow: '5.95px 22.22px 17.89px 20px #15151517',
  boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
  padding: '10px',
  paddingLeft: '20px',

  zIndex: 888,

  [max930]: {
    width: '90%',
    height: '45px',
    gap: '10px',
  },
}));

export const StyledInput = styled('input')(() => ({
  border: '0',
  outline: '0',
  background: 'transparent',
  width: '100%',
  height: '100%',
  padding: '0',
  fontSize: '15px',
  fontWeight: 500,
  color: '#3A3A3A',
  [max930]: {
    fontSize: '12px',
  },
}));

export const StyledButton = styled(Button)(() => ({
  background: '#000',
  borderRadius: '42px',
  width: '100%',
  maxWidth: '114px',
  height: '52px',
  color: '#fff',
  fontSize: '16px',
  fontWeight: '400',
  boxShadow: 'none',

  '&:hover': {
    background: '#000',
    opacity: 0.8,
  },
  [max930]: {
    fontSize: '12px',
    maxWidth: '90px',
    height: '40px',
  },
}));

export const Tags = styled('p')(() => ({
  fontSize: '13px',
  lineHeight: '15px',
  fontWeight: 400,
  color: '#727272',
  display: 'flex',
  whiteSpace: 'nowrap',
  gap: '4px',
  position: 'relative',
  left: '30px',

  [max930]: {
    fontSize: '10px',
    left: '-2px',
  },
}));

export const StyledLink = styled(Link)(() => ({
  textDecorationColor: '#727272',
  color: '#727272',
}));

export const RightAside = styled('aside')(({ theme }) => ({
  width: '100%',
  height: '35vw',
  maxHeight: '1450px',
  display: 'flex',
  overflow: 'hidden',
  borderRadius: '70px',

  [max930]: {
    alignSelf: 'center',
    width: '100%',
    maxWidth: '680px',
    height: '412px',
  },
}));

export const StyledVideo = styled('video')(({ theme }) => ({
  objectFit: 'cover',
  objectPosition: 'center',
  width: '100%',
  marginInline: 'auto',
}));
